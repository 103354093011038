<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Faq</h3>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <button type="button" class="btn btn-primary mb-2" size="sm" style="background-color:#030A8C; border-color: #030A8C" v-b-modal.modal-tambah><i class="flaticon2-plus"></i>Tambah</button>
                        <div>
                            <div style="overflow-x:auto; overflow-y: hidden;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th>No</th>
                                          <th style="width:350px;">Pertanyaan</th>
                                          <th style="width:350px;">Jawaban</th>
                                          <th>Tipe</th>
                                          <th>Publish</th>
                                          <th>Aksi</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in kegiatan" :key="row.id">
                                          <td></td>
                                          <td> <div v-html="row.pertanyaan"></div></td>
                                           <td><div v-html="row.jawaban"></div></td>
                                          <td>{{row.tipe}}</td>
                                          <td>{{row.publis}}</td>
                                          <td>
                                              <div style="width:150px">
                                                  <b-button data-toggle="modal" data-target="#editfaq"
                                                    variant="success"
                                                    size="sm"
                                                    style="backgorund-color:#66A626"
                                                    
                                                    @click="getperfaq(row.id)"
                                                >
                                                    <i class="flaticon-edit"></i>Ubah
                                                </b-button>
                                                &nbsp;
                                             <b-button @click="deletefaq(row.id)" size="sm" variant="danger"><i class="flaticon2-trash"></i> Hapus</b-button>
                                              </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>

            <!-- Tambah -->
            <b-modal
                v-model="show"
                id="modal-tambah"
                ref="modal"
                size="lg"
                title="Tambah Faq"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
                >
                <form ref="form" @submit.stop.prevent="tambahfaq">
                    <b-form-group
                        label="Pertanyaan"
                        label-for="name-input"
                        >
                        <div class="message-editor border-bottom-1">
                            <vue-editor v-model="pertanyaan" />
                        </div>
                    </b-form-group>
                    <b-form-group
                        label="Jawaban"
                        label-for="name-input"
                        >
                        <div class="message-editor border-bottom-1">
                            <vue-editor v-model="jawaban" />
                        </div>
                    </b-form-group>
                    <b-form-group
                        label="Tipe"
                        label-for="name-input"
                        >
                        <div class="message-editor border-bottom-1">
                            <b-form-select v-model="jawabantipe" :options="tipe"></b-form-select>
                        </div>
                    </b-form-group>
                    <b-form-group
                        label="Publish"
                        label-for="name-input"
                        >
                        <div class="message-editor border-bottom-1">
                            <b-form-select v-model="selectedpublish" :options="publish"></b-form-select>
                        </div>
                    </b-form-group>
                </form>
                <template #modal-footer>
                    <div class="w-100">
                    <!-- <p class="float-left">Modal Footer Content</p> -->
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="tambahfaq"
                    >
                        Kirim
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="margin-right: 10px;"
                        @click="show=false"
                    >
                        Tutup
                    </b-button>
                    </div>
                </template>
                </b-modal>


                <!-- modal edit kegiatan -->
                <div class="modal fade" id="editfaq" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Ubah Data Faq</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <b-form-group
                                label="Pertanyaan"
                                label-for="name-input"
                                >
                                <div class="message-editor border-bottom-1">
                                    <vue-editor v-model="getfaq.pertanyaan" />
                                </div>
                            </b-form-group>
                            <b-form-group
                                label="Jawaban"
                                label-for="name-input"
                                >
                                <div class="message-editor border-bottom-1">
                                    <vue-editor v-model="getfaq.jawaban" />
                                </div>
                            </b-form-group>
                            <b-form-group
                                label="Tipe"
                                label-for="name-input"
                                >
                                <div class="message-editor border-bottom-1">
                                    <b-form-select v-model="getfaq.tipe" :options="tipe"></b-form-select>
                                </div>
                            </b-form-group>
                            <b-form-group
                                label="Publish"
                                label-for="name-input"
                                >
                                <div class="message-editor border-bottom-1">
                                    <b-form-select v-model="getfaq.publis" :options="publish"></b-form-select>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                            <button type="button" @click="editfaq(getfaq.id)" style="background-color: #030A8C; border-color: #030A8C; color:white" data-dismiss="modal" class="btn btn-primary">Simpan</button>
                        </div>
                        </div>
                    </div>
                </div>
        </div>
    <!-- </div>
  </div> -->
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import JsonExcel from "vue-json-excel";
import { VueEditor } from "vue2-editor";

Vue.component("downloadExcel", JsonExcel);
export default {
  name: "dashboard",
  components: { VueEditor },
  data() {
      return {
        user: JSON.parse(localStorage.getItem('user')),
        url: localStorage.getItem('cms'),
        token: localStorage.getItem('id_token'),
        show:false,
        test:'hai',
        kegiatan:[],
        search: '',
        pertanyaan:'',
        jawaban: '',
        jawabantipe: null,
        selectedpublish: true,
        publish: [
         { value: true, text: "Ya" },
         { value: false, text: "Tidak" },
       ],
        tipe: [
         { value: 'aplikasi', text: 'Aplikasi' },
         { value: 'instrumen', text: 'Instrumen' },
         { value: 'informasi_pm', text: 'Informasi PM' },
         { value: 'waktu', text: 'Waktu' },
       ],
       getfaq:[],
       json_fields: {
        Agenda: "agenda",
        Tahun: "tahun",
        
        },
      }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Faq" }]);
    this.getdata()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    loaddata(){
        if (this.user.id_role === 'super_user') {
            axios.get(this.url+"/faq", {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
                this.kegiatan = response.data.data;
                this.initDatatable();
            }).catch(error=>{
                console.log(error)
                return error
                // this.loaddata()
                });
        } else {
            this.$router.push({ name: "dashboard" })
        }

            
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            order: [[0, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    resetModal() {
        this.agenda = ''
        this.status_sistem = 'Buka'
        this.tanggal_mulai=''
        this.tanggal_selesai=''
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.tambahfaq()
      },
      tambahfaq() {
        const pertanyaan = this.pertanyaan;
        const jawaban = this.jawaban;
        const tipe = this.jawabantipe;
        const publis = this.selectedpublish;
        axios
            .post(
            this.url+"/faq",
            {
                pertanyaan,
                jawaban,
                tipe,
                publis
            },
            {
            headers: {
                "Content-type": "application/json",
                "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                console.log(res);
                this.getdata()
                // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        
        this.$nextTick(() => {
          this.$bvModal.hide('modal-tambah')
        })
    },
    getperfaq(id){
        axios.get(this.url+`/faq?id=${id}`, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            // axios.get(`http://penkin-penilaian.mysurvey.id/sdm/?filter=idpm,=,`+this.idpm+`;bagian,=,Kepala Dinas`).then(response => {
            this.getfaq = response.data.data;
            console.log(response.data.data)
            console.log('getfaq')
        });
    },
    handleEdit(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.editsdm()
      },
    editfaq(id) {
        axios
            .put(
            this.url+`/faq/${id}`,
            {
                // idptsp,
                jawaban:this.getfaq.jawaban,
                pertanyaan:this.getfaq.pertanyaan,
                publis:this.getfaq.publis,
                tipe:this.getfaq.tipe,
            }
            ,{
                headers:{
                "xth": this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Diedit', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                console.log(res);
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Diedit', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
    },
    deletefaq(id){
        axios
            .delete(
            this.url+`/faq/${id}`,
            {
                headers: {
                    "Content-type": "application/json",
                    "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil Dihapus', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                console.log(res);
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Dihapus', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
  }
};
</script>
